<template>
  <div>
    <div class="about d-none d-sm-flex justify-center flex-column" style="background-color:#fdf9f7;">
      <div>
        <div class="page-title">{{ $t('About.Title') }}</div>
        <div class="content mt-3" v-html="$t('About.Desc')"></div>
      </div>

      <!-- <img src="@/assets/images/about.png" alt="SuperCell" class="page-image"> -->
    </div>

    <div class="about d-block d-sm-none">
      <div class="text-center text-h6">{{ $t('About.Title') }}</div>
      <div class="mt-3 text-content" v-html="$t('About.Desc')"></div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../../components/Footer.vue'


export default {
  components: {
    Footer
  },
  mounted(){
  }
}
</script>

<style lang="scss">
  .about {
    min-height: calc(100vh - 289px);
    padding: 50px 120px;
    // background: url("../../assets/images/about.png") 100% 100%;
    background: url('../../assets/images/about.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right bottom
  }

  @media screen and (max-width: 960px) {
    .about {
      padding: 30px 24px;

      .text-content {
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
</style>
